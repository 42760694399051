import React, { useEffect } from "react";

const Certificats = () => {
	useEffect(() => {
		const scripts = document.getElementById("scripts");

		const script1 = document.createElement("script");
		script1.src = "https://widget.reco.se/yearsInRowBadge/4025944.js";
		script1.async = true;

		const script2 = document.createElement("script");
		script2.src = "https://widget.reco.se/badge/2021/4025944.js";
		script2.async = true;

		const script3 = document.createElement("script");
		script3.src = "https://widget.reco.se/badge/2020/4025944.js";
		script3.async = true;

		const script4 = document.createElement("script");
		script4.src = "https://widget.reco.se/badge/2019/4025944.js";
		script4.async = true;

		const script5 = document.createElement("script");
		script5.src = "https://widget.reco.se/badge/2018/4025944.js";
		script5.async = true;

		scripts.appendChild(script1);
		scripts.appendChild(script2);
		scripts.appendChild(script3);
		scripts.appendChild(script4);
		scripts.appendChild(script5);
	}, []);

	return (
		<div class="bg-white">
			<div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
				<p class="text-center text-sm font-semibold uppercase text-gray-500 tracking-wide certificats-title">
					Certifieringar
				</p>
				<div
					class="mt-6 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5"
					id="scripts"
				>
					<div
						id="reco--badge-yearsInRowBadge"
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					></div>
					<div
						id="reco--badge-2021"
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					></div>
					<div
						id="reco--badge-2020"
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					></div>
					<div
						id="reco--badge-2019"
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					></div>
					<div
						id="reco--badge-2018"
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					></div>
				</div>
			</div>
		</div>
	);
};

export default Certificats;
