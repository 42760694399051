import React, { useState } from "react";
import "./SingleDropdown.scss";

const SingleDropdown = ({ text, description, items, link }) => {
  const [descriptionVisible, setDescriptionVisible] = useState(false);
  return (
    <dl class="mt-6 space-y-6 divide-y divide-gray-200 SingleDropdown">
      <div class="pt-6">
        <dt class="text-lg">
          <button
            type="button"
            class="text-left w-full flex justify-between items-start text-gray-400"
            aria-controls="faq-0"
            aria-expanded="false"
            onClick={() => setDescriptionVisible((p) => !p)}
          >
            <span class="font-medium text-gray-900">{text}</span>
            <span class="ml-6 h-7 flex items-center">
              <svg
                class="rotate-0 h-6 w-6 transform"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </span>
          </button>
        </dt>
        {descriptionVisible &&
          (description ? (
            <dd class="mt-2 pr-12" id="faq-0">
              <p class="text-base text-gray-500">
                {description}{" "}
                {link && (
                  <a
                    href="https://www.skatteverket.se/privat/fastigheterochbostad/rotochrutarbete/villkorforattfarutavdrag.4.3810a01c150939e893f2b9d.html"
                    target="_blanko"
                    className="dropdown-link"
                  >
                    {" "}
                    Läs mer här.
                  </a>
                )}
              </p>
            </dd>
          ) : (
            <ul>
              {items?.map((item) => (
                <li>{item}</li>
              ))}
            </ul>
          ))}
      </div>
    </dl>
  );
};

export default SingleDropdown;
